.tag {
  height: 23px;
  background: #604FED;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 32px;
  box-sizing: border-box;

  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 8px;
  letter-spacing: 0.53em;
  text-transform: uppercase;
  color: #FFFFFF;
}