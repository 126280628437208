.footer {
  background: #302935;
  width: 100%;
}

.content {
  box-sizing: border-box;
  max-width: 1440px;
  padding: 48px 24px 0px 24px;
  margin: 0 auto;
  
  display: flex;
  flex-flow: column;

  justify-content: space-between;

  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #BABCD7;
}

@media (min-width: 640px) {
  .content {
    flex-flow: row;
    padding: 64px 40px 0px 40px;
  }
}

.footnote {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8B94A6;
  max-width: 1440px;
  margin: 180px auto 0px auto;
  padding-bottom: 32px;
  padding: 0px 40px 24px 40px;
  box-sizing: border-box;
}

.content a {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #BABCD7;
}

.content .left p {
  max-width: 240px;
  margin-top: 40px;
}

.content .right {
  display: flex;
  flex-flow: row;
}

.content .right > * {
  width: 185px;
  display: flex;
  flex-flow: column;
  gap: 32px;
}

.content .right .links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.content .right p {
  margin: 0;
}

.content .right .links a {
  text-decoration: none;
}

.logo {
  margin-bottom: 20px;
  display: block;
}
.logo img {
  height: 56px;
}
