.boxes {
  display: flex;
  flex-flow: column;
  gap: 32px;
}

@media (min-width: 640px) {
  .boxes {
    flex-flow: row;
  }
}

.box {
  background: #604FED;
  padding: 32px;
}

.box pre {
  color: white;
}

.verifyBox {
  padding: 32px;
  margin: 0 auto;
}
.link {
  color: #E4A48B;
  text-decoration: underline;
}

.isMobile {
  display: block;
}
.isDesktop {
  display: none;
}
@media (min-width: 640px) {
  .isMobile {
    display: none;
  }
  .isDesktop {
    display: block;
  }
}

.qrBox {
  flex-shrink: 1;
}

@media (min-width: 640px) {
  .qrBox {
    width: 400px;
  }
}

.qrBox p {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #D0D3EB;
}

.qrBox h3 {
  margin-bottom: 32px;
}

.insuranceItem {
  border-top: 1px solid #7C6DF9;
  padding: 32px 0px;
  gap: 8px;
  display: flex;
  flex-flow: column;
}
.insuranceItem:last-child {
  border-bottom: 1px solid #7C6DF9;
}

.insuranceItem p {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #D0D3EB;
  margin: 0;
}
.insuranceItem p a {
  color: #E4A48B;
}

.insuranceItem h3 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.01em;
  text-transform: none;
  color: #D0D3EB;
  margin: 0;
}

.insuranceItem h4 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #E4A48B;
  text-transform: none;
  margin: 0;
}

.claim {
  border-top: 1px solid #7C6DF9;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #D0D3EB;
  padding: 16px 0px;
  display: flex;
  flex-flow: column;
  gap: 4px;
}
.claim:last-child {
  border-bottom: 1px solid #7C6DF9;
}

.claim label {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #E4A48B;

}

.button {
  background: #E4A48B;
  border: 1px solid #E4A48B;
  color: #FFF;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  height: 40px;
  width: 100%;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
}