.header {
}

.top {
  background: #271D7B;
}
.top .content {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 32px 0px 40px;

  max-width: 1440px;
  height: 44px;
}
.top ul {
  margin: 0;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row;
  gap: 32px;
  flex-shrink: 1;
}
.top ul a {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #D0D3EB;
  text-decoration: none;
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.navigation {
  margin: 0 auto;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0px 24px;
  height: 76px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.navigation .left {
  display: flex;
  flex-flow: row;
  gap: 8px;
}
.navigation .left .logo img {
  height: 23px;
}

.navigation .right {
  display: none;
  flex-flow: row;
  gap: 32px;
  align-items: center;
}

@media (min-width: 640px) {
  .navigation {
    padding: 0px 40px;
  }
  .navigation .right {
    display: flex;
  }
}

.navigation .right .links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row;
  gap: 32px;
}

.navigation .right .links a {
  display: block;
  text-decoration: none;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #604FED;
}

.navigation .right .cta {
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #604FED;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  padding: 6px 48px;
  
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #604FED;
}