.wrapper {
  background: url('background.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 33%;
}

.content {
  margin: 0 auto;
  padding: 0px 24px 32px 24px;
  box-sizing: border-box;

  max-width: 1440px;
}

@media (min-width: 640px) {
  .content {
    padding: 0px 40px 96px 40px;
  }
}

.link {
  color: #E4A48B;
  text-decoration: underline;
}

.top h1 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #604FED;
  margin: 0;
  padding: 0;
}

.top {
  margin: 32px 0px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 640px) {
  .top {
    flex-flow: row;
  }
}

.top p {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-width: 528px;
  color: #302935;
  margin: 0;
}

.content h2 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.01em;
  color: #E4A48B;
  margin: 0;
  padding: 0;
}

.content h3 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #E4A48B;
  margin: 0;
  padding: 0;
}